@import 'node_modules/@sch-inventory/advertory/styles/aftenposten/styles';

// INFO:
// Styles defined in this file is used to override the default styles
// provided by the ads-team. We can iterate on these styles here, and
// when we they are battle-tested, we can send them to the ads-team
// so they can be included in the default styles.

// TODO Remove after cogwheel package refactor
div[id='gdprAdTransparencyModal'] {
  z-index: 100002;
}

.advertory-aftenposten-native-ad-wrapper__front,
.advertory-aftenposten-netboard-wrapper__brandboard,
.brandstudio-premium-content-widget {
  margin-bottom: 8px !important;
}

@media screen and (min-width: 768px) {
  .content-main-wrapper {
    .separator-group > .content {
      & > .ap-ad-wrapper > * {
        // Ensure no margin-collapse for children: {{{
        // This is important for the ad-displacer. Otherwise a child element
        // may be larger than the parent and the collision calculation will
        // fail.
        display: inline-block !important;
        min-width: 100% !important;
        position: relative;
        // }}}

        background-color: #fff !important;
        padding-top: var(--space-4) !important;
        padding-bottom: var(--space-4) !important;
        margin-top: var(--space-6) !important;
        margin-bottom: var(--space-6) !important;
        border-top: var(--divider-line-ads) !important;
        border-bottom: var(--divider-line-ads) !important;
        box-shadow:
          0px calc(-1 * var(--space-6)) white,
          0px var(--space-6) white !important;
        z-index: 20 !important;
      }
    }
  }

  .advertory-aftenposten-native-ad-wrapper__front {
    width: 100% !important;
    padding-top: 24px !important;
  }

  .advertory-aftenposten-netboard-wrapper__brandboard,
  .advertory-aftenposten-native-ad-wrapper__article {
    position: relative !important;
  }

  .advertory-aftenposten-netboard-wrapper__front[data-testid='netboard'],
  .advertory-aftenposten-native-ad-wrapper__front[data-testid='nativeAd-content'] {
    display: flex !important;
    justify-content: center !important;
    justify-items: center !important;
    width: 100% !important;
  }

  .advertory-aftenposten-native-ad-wrapper__article[data-testid='nativeAd-content'],
  .advertory-aftenposten-native-ad-wrapper__article[data-testid='nativeAd-default'],
  .advertory-aftenposten-native-ad-wrapper__article[data-testid='netboard'] {
    width: 100% !important;
    margin: auto !important;
  }

  .dr-edition-teaser {
    display: flex !important;
    padding-top: 7px !important;
  }

  #topboard {
    width: 984px;
  }
}
